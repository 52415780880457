<template>
  <div class="login-wrap">
    <div class="ms-login">
      <div class="ms-title">后台管理系统</div>
      <el-form :model="param" :rules="rules" ref="login" label-width="0px" class="ms-content">
        <el-form-item prop="username">
          <el-input v-model="param.username" placeholder="username">
            <template #prepend>
              <el-button icon="el-icon-user"></el-button>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
              type="password"
              placeholder="password"
              v-model="param.password"
              @keyup.enter="submitForm()"
          >
            <template #prepend>
              <el-button icon="el-icon-lock"></el-button>
            </template>
          </el-input>
        </el-form-item>
        <div class="login-btn">
          <el-button type="primary" @click="submitForm()">登录</el-button>
        </div>
        <p class="login-tips">Tips : 。</p>
      </el-form>
    </div>
    <!--    备案号-->
    <div class="ali-report-wrap"><a class="ace-link ace-link-primary ali-report-link"
                                    href="http://idinfo.zjamr.zj.gov.cn/bscx.do?spm=a2c4g.11186623.J_9220772140.113.7a76b3422DDRSC&amp;method=lzxx&amp;id=3301963301080000025024"
                                    target="_blank" data-spm-anchor-id="a2c4g.11186623.J_9220772140.113"><img
        data-src="//gw.alicdn.com/tfs/TB1GxwdSXXXXXa.aXXXXXXXXXXX-65-70.gif" class="ali-report-img"
        src="//gw.alicdn.com/tfs/TB1GxwdSXXXXXa.aXXXXXXXXXXX-65-70.gif"></a><a
        class="ace-link ace-link-primary ali-report-link"
        href="http://www.beian.gov.cn/portal/registerSystemInfo?spm=a2c4g.11186623.J_9220772140.114.7a76b3422DDRSC"
        target="_blank" data-spm-anchor-id="a2c4g.11186623.J_9220772140.114"><img
        data-src="//img.alicdn.com/tfs/TB1..50QpXXXXX7XpXXXXXXXXXX-40-40.png" class="ali-report-img"
        src="//img.alicdn.com/tfs/TB1..50QpXXXXX7XpXXXXXXXXXX-40-40.png"><span
        class=""></span></a><a
        class="ace-link ace-link-primary ali-report-link"
        href="https://beian.miit.gov.cn/?spm=a2c4g.11186623.J_9220772140.115.7a76b3422DDRSC" target="_blank"
        data-spm-anchor-id="a2c4g.11186623.J_9220772140.115"><span
        class="ali-report-link-text ali-report-link-text-unique"
        data-spm-anchor-id="a2c4g.11186623.J_9220772140.i0.7a76b3422DDRSC">
粤ICP备2021071194号-1  </span></a></div>
  </div>
</template>

<script>
import api from '../api/api'
import {ElMessage} from 'element-plus';

export default {

  data() {
    return {
      param: {
        username: "",
        password: ""
      },
      rules: {
        username: [
          {required: true, message: "请输入用户名", trigger: "blur"}
        ],
        password: [
          {required: true, message: "请输入密码", trigger: "blur"}
        ]
      }
    };
  },
  created() {
    this.$store.commit("clearTags");
  },
  methods: {
    submitForm() {
      this.$refs.login.validate(valid => {
        if (valid) {
          this._signIn();
        } else {
          this.$message.error("请输入账号和密码");
          return false;
        }
      });
    },
    _signIn() {
      api.post("/user/signIn", this.param, {})
          .then(res => {
            console.log(res);
            if (res.code === 0) {
              this.$message.success("登录成功");
              sessionStorage.setItem("ly_user", res.data.username);
              sessionStorage.setItem("token", res.data.token);
              this.$router.push("/");
            } else {
              ElMessage({type: "error", message: res.message})
            }
          }).catch(err => {
        console.log(err);
      });
    }
  }
};
</script>

<style scoped>
.ali-report-wrap {
  padding: 15px;
  position: absolute;
  width: 100%;
  align-items: center;
  bottom: 0;
  height: auto;
}

.ali-report-wrap .ali-report-link {
  display: inline-block;
  text-decoration: none;
}

.ali-report-wrap .ali-report-link .ali-report-link-text {
  height: 20px;
  line-height: 20px;
  color: #ccc;
  margin: 5px;
  font-size: 14px;
}

.ali-report-wrap .ali-report-link img {
  width: 20px;
  display: inline-block;
  height: 20px;
  margin-right: 12px;
}

.ali-report-wrap .ali-report-link .ali-report-link-text-unique {
  margin-left: 12px;
}


.login-wrap {
  position: relative;
  width: 100%;
  height: 100%;
  background-image: url(../assets/img/login-bg.jpg);
  background-size: 100%;
}

.ms-title {
  width: 100%;
  line-height: 50px;
  text-align: center;
  font-size: 20px;
  color: #fff;
  border-bottom: 1px solid #ddd;
}

.ms-login {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 350px;
  margin: -190px 0 0 -175px;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.3);
  overflow: hidden;
}

.ms-content {
  padding: 30px 30px;
}

.login-btn {
  text-align: center;
}

.login-btn button {
  width: 100%;
  height: 36px;
  margin-bottom: 10px;
}

.login-tips {
  font-size: 12px;
  line-height: 30px;
  color: #fff;
}
</style>
