<template>
  <div class="top-nav">
    <div class="container clear-float">
      <div class="fl">
        <a href="#">首页</a>
      </div>
      <div class="fr">
        <a class="item" href="#">我的订单</a>
        <a class="item" href="#">收藏夹</a>
        <a class="item" href="#">登陆</a>
        <a class="item" href="#">注册</a>
      </div>
    </div>
  </div>
  <div class="header">
    <div class="container clear-float">
      <div class="logo col-2">
        香蕉街
      </div>
      <div class="col-5 search-bar">
        <input type="text">
        <button>搜索</button>
      </div>
      <div class="col-3 cart">
        <a href="#">我的购物车</a>
      </div>
    </div>
  </div>

  <div class="main-promote clear-float">
    <div class="container">
      <div class="col-2 cat">
        <div class="item">item/item1</div>
        <div class="item">item/item1</div>
        <div class="item">item/item1</div>
        <div class="item">item/item1</div>
        <div class="item">item/item1</div>
        <div class="item">item/item1</div>
        <div class="item">item/item1</div>
        <div class="item">item/item1</div>
        <div class="item">item/item1</div>
        <div class="item">item/item1</div>
        <div class="item">item/item1</div>
      </div>
      <div class="col-6">
        <div class="slider">
          <img src="img/slide_01.png" alt="">
        </div>
        <div class="sub-promote">
          <div class="col-5">
            <img src="img/001.png" alt="">
          </div>
          <div class="col-5">
            <img src="img/002.png" alt="">
          </div>
        </div>
      </div>
      <div class="col-2 info">
        <div class="auth">
          <div class="avatar">
          </div>
          <p>你好，欢迎剁手~</p>
        </div>
        <br/>
        <div class="anno">
          <div class="title">公告</div>
          <div class="content">
            公告内容公告内容公告内容公告内容公告内容公告内容公告内容公告内容公告内容公告内容
            公告内容公告内容公告内容公告内容公告内容公告内容公告内容公告内容公告内容公告内容
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="cat-promote  clear-float">
      <div class="title">女装</div>
      <div class="content">
        <div class="col-2 item">
          <div class="card"></div>
        </div>
        <div class="col-3 item">
          <div class="card"></div>
        </div>
        <div class="col-2 item">
          <div class="card"></div>
        </div>
        <div class="col-3 item">
          <div class="card"></div>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="cat-promote  clear-float">
      <div class="title">男装</div>
      <div class="content">
        <div class="col-1 item">
          <div class="card"></div>
        </div>
        <div class="col-2 item">
          <div class="card"></div>
        </div>
        <div class="col-3 item">
          <div class="card"></div>
        </div>
        <div class="col-4 item">
          <div class="card"></div>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="cat-promote  clear-float">
      <div class="title">童装</div>
      <div class="content">
        <div class="col-3 item">
          <div class="card"></div>
        </div>
        <div class="col-2 item">
          <div class="card"></div>
        </div>
        <div class="col-3 item">
          <div class="card"></div>
        </div>
        <div class="col-2 item">
          <div class="card"></div>
        </div>
      </div>
    </div>
  </div>
  <div class="footer  clear-float">
    <div class="container">
      <a href="#">item</a>
      <a href="#">item</a>
      <a href="#">item</a>
      <a href="#">item</a>
      <a href="#">item</a>
      <div>© 2019 GitHub, Inc</div>
      </div>
    </div>
</template>

<script>

export default {

  data() {
    return {
      param: {
        username: "",
        password: ""
      },
      rules: {
        username: [
          {required: true, message: "请输入用户名", trigger: "blur"}
        ],
        password: [
          {required: true, message: "请输入密码", trigger: "blur"}
        ]
      }
    };
  },
  created() {

  },
  methods: {


  }
};
</script>

<style scoped>
*{
  background: rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  transition: background 200ms;
}
body{
  font-size: 14px;
  color:gray;
  background: #f5f5f5;
}
img{
  display: block;
  max-width: 100%;
}
/*包含类*/
.container{
  display: block;
  max-width: 1080px;
  margin: 0 auto;
}
/*左浮动和右浮动*/
.fl{
  float: left;
}
.fr{
  float: right;
}

/*清除浮动*/
.clear-float:after,
.clear-float:before{
  content: " ";
  display: block;
  clear: both;
}
.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9{
  display:block;
  position: relative;
  min-height: 1px;
  float: left;
}
.col-1{
  width: 10%;
}
.col-2{
  width: 20%;
}
.col-3{
  width:30%;
}
.col-4{
  width: 40%;
}
.col-5{
  width: 50%;
}
.col-6{
  width: 60%;
}
.col-7{
  width: 70%;
}
.col-8{
  width: 80%;
}
.col-9{
  width: 90%;
}
.top-nav{
  background:#eee;
}
.top-nav .item{
  display: inline-block;
  padding: 6px 10px;
  color: #666;
}
.top-nav .item:hover{
  color: #333;
}
.header{
  padding: 20px 0;
}
.header .logo{
  font-size: 30px;
}
.header .search-bar{
  border: 2px solid #dd182b;
}
.header .search-bar input,
.header .search-bar button{
  display: block;
  float: left;
  border: 0px;
  padding: 10px;
  outline: 0;
}
.header .search-bar input{
  width: 80%;
}
.header .search-bar:focus{
  box-shadow: inset 0 0 2px 2px rgba(0, 0, 0, 0.3);
}
.header .search-bar button{
  width: 20%;
  background: #dd182b;
  color: #fff;
}
.header .search-bar button:hover{
  background: #ddce1829;
}
.header .cart{
  text-align: center;
}
.header .cart a{
  float: right;
  width: 80%;
  padding: 10px;
  background: #fff;
  border: 1px solid #eee;
}
.main-promote{
  background: #dedede;
}
.main-promote .cat{
  background: #6e6568;
  color: #fff;
  padding: 10px;
}
.main-promote .cat .item{
  padding: 14px 20px;
}
.main-promote .cat .item:hover{
  background: rgba(0, 0, 0, .2);
}
.main-promote .info{
  padding: 10px;
  background: #fff;
  color: #888;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}
.main-promote .info > * {
  margin-bottom: 10px;
}
.main-promote .info .avatar{
  width: 50px;
  height: 50px;
  background: #aaa;
  border-radius: 50%;
  float: left;
  margin-right: 10px;
}
.cat-promote .title:before{
  content: "";
  display: inline-block;
  vertical-align: middle;
  width: 5px;
  height: 25px;
  background: #dd182b;
  margin-right: 10px;
}
.cat-promote .title{
  font-size: 22px;

}
.cat-promote .item{
  padding: 5px;
}
.cat-promote .card{
  height: 300px;
  background: #cccccc;
  border-radius: 6px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
}
.footer{
  text-align: center;
  color: #999;
  background: #eee;
  padding: 30px 0;
  margin-top: 30px
}
.footer a{
  color:#999;

}
</style>
